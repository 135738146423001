<template>
    <svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_2230_8717)">
<g clip-path="url(#clip0_2230_8717)">
<path d="M24.9874 4H4V17H24.9874V4Z" fill="#FDFFFF"/>
<path d="M24.9874 10.2819H4V17H24.9874V10.2819Z" fill="#DB2E2E"/>
<path d="M25 8.27515H4V12.65H25V8.27515Z" fill="#3C5EBF"/>
</g>
</g>
<defs>
<filter id="filter0_d_2230_8717" x="0" y="0" width="29" height="21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2230_8717"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2230_8717" result="shape"/>
</filter>
<clipPath id="clip0_2230_8717">
<rect x="4" y="4" width="21" height="13" rx="3" fill="white"/>
</clipPath>
</defs>
</svg>

</template>