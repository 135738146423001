<i18n lang="json">
{
  "ru": {
    "ru": "RUS",
    "ab": "ABH",
    "en": "ENG",
    "tr": "TUR",
    "es": "ESP",
    "ar": "ARB",
    "zh": "CHI"
  },
  "en": {
    "ru": "RUS",
    "ab": "ABH",
    "en": "ENG",
    "tr": "TUR",
    "es": "ESP",
    "ar": "ARB",
    "zh": "CHI"
  },
  "ab": {
    "ru": "RUS",
    "ab": "ABH",
    "en": "ENG",
    "tr": "TUR",
    "es": "ESP",
    "ar": "ARB",
    "zh": "CHI"
  },
  "tr": {
    "ru": "RUS",
    "ab": "ABH",
    "en": "ENG",
    "tr": "TUR",
    "es": "ESP",
    "ar": "ARB",
    "zh": "CHI"
  },
  "es": {
    "ru": "RUS",
    "ab": "ABH",
    "en": "ENG",
    "tr": "TUR",
    "es": "ESP",
    "ar": "ARB",
    "zh": "CHI"
  },
  "ar": {
    "ru": "RUS",
    "ab": "ABH",
    "en": "ENG",
    "tr": "TUR",
    "es": "ESP",
    "ar": "ARB",
    "zh": "CHI"
  },
  "zh": {
    "ru": "RUS",
    "ab": "ABH",
    "en": "ENG",
    "tr": "TUR",
    "es": "ESP",
    "ar": "ARB",
    "zh": "CHI"
  }
}
</i18n>

<script setup>
const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <select
    id="language"
    v-model="languageSelector"
    name="language"
    class="header-content__select coursor-pointer focus:outline-none px-2"
  >
    {{
      $i18n
    }}
    <option selected :value="$i18n.locale">{{ t($i18n.locale) }}</option>

    <option
      v-for="locale in availableLocales"
      :key="locale.code"
      :value="locale.code"
    >
      {{ t(locale.code) }}
    </option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      languageSelector: this.$i18n.locale,
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },
  watch: {
    languageSelector() {
      this.$router.push(this.switchLocalePath(this.languageSelector))
    },
  },
}
</script>
<style scoped>
.header-content__select {
  line-height: 24px;
  font-size: 16px;
  background: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: 40px 6px;
  
}
</style>