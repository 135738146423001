<script setup lang="ts">

</script>
<template>
  <div>
    <ABankChat typeButton="secondary"/>
    <AHeader></AHeader>
    <slot />
    <AFooter></AFooter>
  </div>
</template>
