<template>
    <svg width="24" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_710_11280)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.125 5.25C9.82663 5.25 9.54048 5.36853 9.3295 5.5795C9.11853 5.79048 9 6.07663 9 6.375V8.25C9 8.66421 8.66421 9 8.25 9C7.83579 9 7.5 8.66421 7.5 8.25V6.375C7.5 5.67881 7.77656 5.01113 8.26884 4.51884C8.76113 4.02656 9.42881 3.75 10.125 3.75H19.875C20.5712 3.75 21.2389 4.02656 21.7312 4.51884C22.2234 5.01113 22.5 5.67881 22.5 6.375V17.625C22.5 18.3212 22.2234 18.9889 21.7312 19.4812C21.2389 19.9734 20.5712 20.25 19.875 20.25H10.125C9.42881 20.25 8.76113 19.9734 8.26884 19.4812C7.77656 18.9889 7.5 18.3212 7.5 17.625V15.75C7.5 15.3358 7.83579 15 8.25 15C8.66421 15 9 15.3358 9 15.75V17.625C9 17.9234 9.11853 18.2095 9.3295 18.4205C9.54048 18.6315 9.82663 18.75 10.125 18.75H19.875C20.1734 18.75 20.4595 18.6315 20.6705 18.4205C20.8815 18.2095 21 17.9234 21 17.625V6.375C21 6.07663 20.8815 5.79048 20.6705 5.5795C20.4595 5.36853 20.1734 5.25 19.875 5.25H10.125Z" fill="#6F6F6F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2197 7.71967C12.5126 7.42678 12.9874 7.42678 13.2803 7.71967L17.0303 11.4697C17.3232 11.7626 17.3232 12.2374 17.0303 12.5303L13.2803 16.2803C12.9874 16.5732 12.5126 16.5732 12.2197 16.2803C11.9268 15.9874 11.9268 15.5126 12.2197 15.2197L14.6893 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H14.6893L12.2197 8.78033C11.9268 8.48744 11.9268 8.01256 12.2197 7.71967Z" fill="#6F6F6F"/>
</g>
<defs>
<clipPath id="clip0_710_11280">
<rect width="22" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

</template>