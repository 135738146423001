<template>
    <svg width="184" height="44" viewBox="0 0 184 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_2230_7775)">
<path d="M38.8387 22C38.8387 31.8342 31.0398 39.8064 21.4194 39.8064C11.7989 39.8064 4 31.8342 4 22C4 12.1658 11.7989 4.19354 21.4194 4.19354C31.0398 4.19354 38.8387 12.1658 38.8387 22Z" fill="#FFED00"/>
</g>
<path d="M16.0019 33.6737H16.0606C16.2299 33.6737 16.3437 33.5001 16.2667 33.3494C15.6902 32.2199 14.8844 31.7963 13.0401 31.4946C12.9364 31.4777 12.8343 31.4486 12.7388 31.405C11.393 30.7916 10.8861 30.2308 10.2648 29.2705C10.1592 29.1073 10.0987 28.9187 10.0782 28.7254C9.91011 27.1403 9.48023 26.4319 8.38185 25.763C8.25069 25.6831 8.08574 25.78 8.08574 25.9335C8.08574 28.1421 8.04536 30.6492 9.6546 32.1618C9.77046 32.2707 9.8939 32.374 10.026 32.4724C11.6871 33.7094 13.9308 33.6737 16.0019 33.6737Z" fill="#212123"/>
<path d="M27.0186 10.3268H26.9599C26.7907 10.3268 26.6768 10.5004 26.7538 10.6511C27.3303 11.7806 28.1361 12.2042 29.9804 12.5059C30.0841 12.5228 30.1862 12.5519 30.2818 12.5955C31.6275 13.2089 32.1344 13.7697 32.7557 14.7299C32.8613 14.8931 32.9218 15.0818 32.9423 15.2751C33.1104 16.8602 33.5403 17.5686 34.6387 18.2375C34.7698 18.3173 34.9348 18.2205 34.9348 18.067C34.9348 15.8584 34.9751 13.3513 33.3659 11.8387C33.25 11.7298 33.1266 11.6265 32.9945 11.5281C31.3334 10.291 29.0897 10.3268 27.0186 10.3268Z" fill="#212123"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.1128 25.8198C21.0426 25.8198 24.1224 22.7454 24.1224 19.0819C24.1224 15.4183 21.0426 12.344 17.1128 12.344C13.1829 12.344 10.1032 15.4183 10.1032 19.0819C10.1032 22.7454 13.1829 25.8198 17.1128 25.8198ZM17.1128 27.837C22.0981 27.837 26.1396 23.9172 26.1396 19.0819C26.1396 14.2466 22.0981 10.3268 17.1128 10.3268C12.1274 10.3268 8.08594 14.2466 8.08594 19.0819C8.08594 23.9172 12.1274 27.837 17.1128 27.837Z" fill="#020202"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.9079 31.6564C29.8378 31.6564 32.9175 28.5821 32.9175 24.9186C32.9175 21.255 29.8378 18.1807 25.9079 18.1807C21.9781 18.1807 18.8983 21.255 18.8983 24.9186C18.8983 28.5821 21.9781 31.6564 25.9079 31.6564ZM25.9079 33.6737C30.8933 33.6737 34.9347 29.7539 34.9347 24.9186C34.9347 20.0832 30.8933 16.1635 25.9079 16.1635C20.9225 16.1635 16.8811 20.0832 16.8811 24.9186C16.8811 29.7539 20.9225 33.6737 25.9079 33.6737Z" fill="#020202"/>
<path d="M16.8811 16.388V25.3676L19.1957 19.9799V14.5921H18.677C17.6852 14.5921 16.8811 15.3962 16.8811 16.388Z" fill="#212123"/>
<path d="M26.1394 27.837V18.8574L23.8248 24.2451V29.6329H24.3435C25.3353 29.6329 26.1394 28.8288 26.1394 27.837Z" fill="#212123"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.2258 27.9473C53.7135 27.9473 55.7302 25.8045 55.7302 23.1613C55.7302 20.5181 53.7135 18.3754 51.2258 18.3754C48.7381 18.3754 46.7214 20.5181 46.7214 23.1613C46.7214 25.8045 48.7381 27.9473 51.2258 27.9473ZM51.2258 29.742C54.6464 29.742 57.4193 26.7957 57.4193 23.1613C57.4193 19.5269 54.6464 16.5807 51.2258 16.5807C47.8052 16.5807 45.0322 19.5269 45.0322 23.1613C45.0322 26.7957 47.8052 29.742 51.2258 29.742Z" fill="#212123"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M103.871 27.9473C106.359 27.9473 108.375 25.8045 108.375 23.1613C108.375 20.5181 106.359 18.3754 103.871 18.3754C101.383 18.3754 99.3666 20.5181 99.3666 23.1613C99.3666 25.8045 101.383 27.9473 103.871 27.9473ZM103.871 29.742C107.292 29.742 110.065 26.7957 110.065 23.1613C110.065 19.5269 107.292 16.5807 103.871 16.5807C100.45 16.5807 97.6775 19.5269 97.6775 23.1613C97.6775 26.7957 100.45 29.742 103.871 29.742Z" fill="#212123"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M146.452 27.9473C148.939 27.9473 150.956 25.8045 150.956 23.1613C150.956 20.5181 148.939 18.3754 146.452 18.3754C143.964 18.3754 141.947 20.5181 141.947 23.1613C141.947 25.8045 143.964 27.9473 146.452 27.9473ZM146.452 29.742C149.872 29.742 152.645 26.7957 152.645 23.1613C152.645 19.5269 149.872 16.5807 146.452 16.5807C143.031 16.5807 140.258 19.5269 140.258 23.1613C140.258 26.7957 143.031 29.742 146.452 29.742Z" fill="#212123"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M131.355 27.9473C133.998 27.9473 136.141 25.8045 136.141 23.1613C136.141 20.5181 133.998 18.3754 131.355 18.3754C128.712 18.3754 126.569 20.5181 126.569 23.1613C126.569 25.8045 128.712 27.9473 131.355 27.9473ZM131.355 29.742C134.989 29.742 137.935 26.7957 137.935 23.1613C137.935 19.5269 134.989 16.5807 131.355 16.5807C127.72 16.5807 124.774 19.5269 124.774 23.1613C124.774 26.7957 127.72 29.742 131.355 29.742Z" fill="#212123"/>
<path d="M127.871 15.8065V13.4839H132.903L127.871 15.8065Z" fill="#212123"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M88.7742 27.9473C91.4174 27.9473 93.5602 25.8045 93.5602 23.1613C93.5602 20.5181 91.4174 18.3754 88.7742 18.3754C86.1311 18.3754 83.9883 20.5181 83.9883 23.1613C83.9883 25.8045 86.1311 27.9473 88.7742 27.9473ZM88.7742 29.742C92.4086 29.742 95.3549 26.7957 95.3549 23.1613C95.3549 19.5269 92.4086 16.5807 88.7742 16.5807C85.1399 16.5807 82.1936 19.5269 82.1936 23.1613C82.1936 26.7957 85.1399 29.742 88.7742 29.742Z" fill="#212123"/>
<path d="M55.8708 16.5807H57.4192V29.742H55.8708V16.5807Z" fill="#212123"/>
<path d="M108.516 16.5807H110.065V29.742H108.516V16.5807Z" fill="#212123"/>
<path d="M151.097 16.5807H152.645V29.742H151.097V16.5807Z" fill="#212123"/>
<path d="M124.774 13.4839H126.323V29.742H124.774V13.4839Z" fill="#212123"/>
<path d="M157.29 16.5807H158.839V29.742H157.29V16.5807Z" fill="#212123"/>
<path d="M166.581 16.5807H168.129V29.742H166.581V16.5807Z" fill="#212123"/>
<path d="M172.774 16.5807H174.323V29.742H172.774V16.5807Z" fill="#212123"/>
<path d="M180.36 16.892L182.604 16.892L176.27 23.5927L175.016 22.3701L180.36 16.892Z" fill="#212123"/>
<path d="M181.21 29.5582L183.51 29.6143L176.854 22.5836L175.308 23.4591L181.21 29.5582Z" fill="#212123"/>
<path d="M82.1936 16.5807H83.742V32.8388H82.1936V16.5807Z" fill="#212123"/>
<path d="M64.5489 17.3549L59.7419 29.742H62.1454L65.4502 20.894L69.3558 29.742H70.5575L74.4632 20.894L77.7679 29.742H79.871L75.064 17.3549H73.5619L70.2571 26.2028H69.6562L66.051 17.3549H64.5489Z" fill="#212123"/>
<path d="M113.161 22.0001H121.678V23.5484H113.161V22.0001Z" fill="#212123"/>
<path d="M158.839 22.0001H166.581V23.5484H158.839V22.0001Z" fill="#212123"/>
<path d="M174.323 22.0001H176.645V23.5484H174.323V22.0001Z" fill="#212123"/>
<defs>
<filter id="filter0_d_2230_7775" x="0" y="0.193542" width="42.8386" height="43.6129" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2230_7775"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2230_7775" result="shape"/>
</filter>
</defs>
</svg>

</template>